// Import all stylesheets from @wfp/ui library
@import '@wfp/ui/src/globals/scss/styles.scss';
// Import local styles
@import './loadingError';
@import './outdatedBrowserBanner';

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.wfp--wrapper .wfp--wrapper {
  padding: 0;
}

.wfp--main-navigation__logo a {
  font-size: 1.25rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.wfp--main-navigation__trigger a,
.wfp--main-navigation__trigger button {
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.wfp--link {
  box-sizing: border-box;
}

.wfp--breadcrumb-item:first-child {
  svg {
    height: 1rem;
    width: 1rem;
  }
}

.wfp--breadcrumb-item::after {
  content: '›';
  font-weight: 600;
  margin-top: -0.125rem;
  margin-left: 0.75rem;
  color: #0b77c2;
}

.wfp--user__title {
  font-weight: bold;
}

.wfp--modal.is-visible {
  z-index: 10000;
}

.wfp-main-navigation__item--main-menu {
  .wfp--main-navigation__sub--open {
    right: 0;
    left: inherit;
    width: auto;
  }
}

#id_create_request_link {
  background-color: #3b94d3;

  &:hover,
  &:focus {
    background-color: #085992;
  }

  &:focus {
    border: 2px solid #eff2f5;
    outline: 2px solid #085992;
  }

  &:active {
    background-color: #053c62;
  }
}

.wss-skeleton-element {
  position: relative;
  background: rgba(11, 119, 194, 0.1);
  color: transparent !important;
  pointer-events: none;
  user-select: none;

  &:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(11, 119, 194, 0.1);
    animation: 3000ms ease-in-out skeleton infinite;
  }
}

// TODO: remove this when it'll be fixed in @wfp/ui library
.wfp--pagination .wfp--select--inline .wfp--select-input ~ .wfp--select__arrow {
  right: 3px;
  top: 9px;
}
