$main-color: #fff;
$text-color: #031c2d;
$stroke-color: #031c2d;

.loading-details-overlay-wrapper {
  background: $main-color;
  position: fixed;
  font-family: 'Open Sans', sans-serif;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 99999;

  &--fading {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
}

.loading-details-overlay {
  flex: 1 0 auto;
  padding-top: 100px;
}

.loading-details-overlay__text {
  margin: 20px auto 23px auto;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
  // font-weight: 600;
  line-height: 1.5;
  color: $text-color;
  max-width: 650px;
}

.loading-details-overlay__text,
.loading-details-overlay__text--loading {
  -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear; /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;
}
.loading-details-overlay-wrapper--loading .loading-details-overlay__text,
.loading-details-overlay__text--loading {
  margin-top: 0;
  opacity: 0;
  font-size: 0;
}

.loading-details-overlay__text--loading {
  margin: 0 auto;
  height: 0;
}

.loading-details-overlay-wrapper--loading
  .loading-details-overlay__text.loading-details-overlay__text--loading {
  margin-bottom: 3rem;
  opacity: 1;
  font-size: 1.5rem;
}

.loading-details-overlay-gears {
  position: relative;
  margin: 0 auto;
  width: auto;
  height: 0;
}
.loading-details-overlay-gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: $stroke-color;

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 2;
    content: '';
    border-radius: 50%;
    background: $main-color;
  }

  &:after {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 3;
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid $stroke-color;
    box-sizing: border-box;
    background: $main-color;
  }

  &.one {
    left: -130px;
  }
  &.two {
    top: -75px;
  }
  &.three {
    top: -235px;
    left: 130px;
  }
  & .bar {
    position: absolute;
    left: -15px;
    top: 50%;
    z-index: 0;
    width: 150px;
    height: 30px;
    margin-top: -15px;
    border-radius: 5px;
    background: $stroke-color;
  }
  & .bar:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    content: '';
    border-radius: 2px;
    background: $main-color;
  }
  & .bar:nth-child(2) {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
  }
  & .bar:nth-child(3) {
    transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
  }
}

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes anticlockwise {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes clockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(30deg);
  }
  40% {
    -webkit-transform: rotate(25deg);
  }
  60% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes anticlockwiseErrorStop {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes anticlockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  40% {
    -webkit-transform: rotate(-25deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.loading-details-overlay-gear.one {
  -webkit-animation: anticlockwiseErrorStop 2s linear infinite;
}
.loading-details-overlay-gear.two {
  -webkit-animation: anticlockwiseError 2s linear infinite;
}
.loading-details-overlay-gear.three {
  -webkit-animation: clockwiseError 2s linear infinite;
}
.loading-details-overlay-wrapper--loading .loading-details-overlay-gear.one,
.loading-details-overlay-wrapper--loading .loading-details-overlay-gear.three {
  -webkit-animation: clockwise 3s linear infinite;
}
.loading-details-overlay-wrapper--loading .loading-details-overlay-gear.two {
  -webkit-animation: anticlockwise 3s linear infinite;
}
