#outdated {
  display: none;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  p {
    font-size: 14px;
  }
}
